import './_hero-header.scss';
import { popupController } from "JS/components/_popup";

const heroHeader = function () {
	popupController();

    const blocks = document.querySelectorAll('section.hero-header')

    for (const block of blocks) {
        let scrollableDivs = block.querySelectorAll('.scrollable');

        for (const scrollableDiv of scrollableDivs) {

            setInterval(function () {
                let show = scrollableDiv.querySelector('.slide-text[data-show]')
                if (show != null) {
                    let next = show.nextElementSibling || scrollableDiv.querySelector('.slide-text:first-child')
                    let up = scrollableDiv.querySelector('.slide-text[data-up]')

                    if (up) {
                        up.removeAttribute('data-up')
                    }
                    show.removeAttribute('data-show')
                    show.setAttribute('data-up', '')

                    next.setAttribute('data-show', '')
                }
            }, 2000);
        }
    }
};

document.addEventListener('DOMContentLoaded', function () {
    heroHeader()
}, false);

window.addEventListener('acf/loaded/hero-header', function (e) {
    heroHeader()
});